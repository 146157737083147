<template>
    <form class="action-form" @submit.prevent="rowEditSave(currentlySelectedAction)"
        :class="{ 'loading-cursor': loading }">

        <div class="form-field">

            <div class="form-field">
                <h4 class="form-title">{{ $t('detailedAnalysisForm.selectedText') }}:</h4>
                <v-textarea auto-grow rows="1" type="text" class="text-field"
                    v-model="currentlySelectedAction.summary" />
            </div>

            <h4 class="form-title">{{ $t('actionsForm.action-assign') }}</h4>
            <Dropdown v-model="currentlySelectedAction.info" :options="actions" optionGroupLabel="label"
                optionGroupChildren="items" :placeholder="$t('actionsForm.no-input')" class="w-full md:w-14rem">
                <template #value="slotProps">
                    <div v-if="currentlySelectedAction.info !== null">{{ $t('actionsForm.' +
            currentlySelectedAction.info) }}</div>
                </template>
                <template #optiongroup="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ $t('actionsForm.' + slotProps.option.label) }}</div>
                    </div>
                </template>
                <template #option="slotProps">
                    <div class="flex align-items-center">
                        <div>{{ $t('actionsForm.' + slotProps.option) }}</div>
                    </div>
                </template>
            </Dropdown>
            <p v-if="!formIsValid && currentlySelectedAction.info === 'no-input'" class="error">
                {{ $t('actionsForm.selectDropdown') }}
            </p>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('actionsForm.user-assign') }}</h4>
            <Dropdown v-model="currentlySelectedAction.assigned_user_email" :options="users"
                :placeholder="$t('detailedAnalysisForm.assignUser')"></Dropdown>

        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('actionsForm.comment') }}:</h4>
            <textarea v-model="currentlySelectedAction.comment"></textarea>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('actionsForm.deadline') }}:</h4>
            <Calendar v-model="currentlySelectedAction.deadline" dateFormat="yy-mm-dd" :minDate="minDate" show-icon />
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('actionsForm.completed') }}:</h4>
            <Checkbox v-model="currentlySelectedAction.completed" :binary="true" />
        </div>

        <div class="buttons-field">
            <Button class="form-button" :disabled="loading" :label="$t('detailedAnalysisForm.cancel')"
                icon="pi pi-times" @click="emit('closeForm')" text />
            <Button class="form-button" :disabled="loading" :label="$t('detailedAnalysisForm.saveAction')" type="submit"
                icon="pi pi-check" autofocus />
        </div>
    </form>
</template>

<script setup>
import { ref, onMounted } from 'vue';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';

import actions from '@/assets/JSON/actions.json';
import axiosInstance from '@/services/interceptor';
import { formatDate } from '@/helpers/helpers';
import { devLog } from '@/helpers/helpers';


const props = defineProps({
    selectedAction: Object,
    actionList: Array
});

onMounted(() => {
    getUsers();
})

const emit = defineEmits(['closeForm', 'saveRowData'])

const loading = ref(false);
const users = ref([]);
const userObjects = ref([]);
const minDate = ref(new Date());
// this makes sure currentlySelectedAction and props.selectedAction aren't linked
const currentlySelectedAction = ref({ ...props.selectedAction });

const formIsValid = ref(true);

function rowEditSave(data) {
    loading.value = true;
    // get old action
    let oldAction = props.actionList.find((action) => action.id === data.id);
    // oldAction === data => always results in a false, so we need to check every field
    if (oldAction.section === data.section && oldAction.info === data.info && oldAction.assigned_user_email === data.assigned_user_email
        && oldAction.deadline === data.deadline && oldAction.completed === data.completed && oldAction.comment === data.comment) {
        loading.value = false;
        emit('closeForm');
        return
    } else {
        // if email edited, value of the id of the newly selected email needs to be assigned
        if (oldAction.assigned_user_email !== data.assigned_user_email) {
            let index = users.value.indexOf(data.assigned_user_email);
            data.assigned_user = userObjects.value[index].id;
        }
        if (oldAction.deadline !== data.deadline) {
            let index = users.value.indexOf(data.assigned_user_email);
            data.deadline = formatDate(data, 'deadline')
        }
        // do a put request to save edited action on db
        putAction(data);
        // need to show saved data locally
        emit('saveRowData', data);
    }
}


function putAction(action) {
    axiosInstance.put('actions/' + action.id + '/', action)
        .then((response) => {
            loading.value = false;
            emit('closeForm')
        })
        //handle error
        .catch((error) => {
            devLog(error)
        })
        //always executed
        .then(() => {
        });
}


function getUsers() {
    axiosInstance.get('/users/')
        .then((response) => {
            for (const index in response.data) {
                users.value.push(response.data[index].email)
            }
            userObjects.value = response.data;
        })
        .catch(() => {

        })
        .then(() => {
        })
}


</script>

<style scoped>
/* FORM */
.action-form,
.action-form-empty,
.action-form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 100%;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

.text-field {
    color: var(--main-dark-purple);
    font-style: italic;
}

.loading-cursor {
    cursor: wait;
}
</style>