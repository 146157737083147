<template>

    <form class="action-form" @submit.prevent="handleFormSubmission">

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditDerogationForm.advice') }}:</h4>
            <textarea v-model="derogationForm.advice"></textarea>
            <p v-if="errorType === 'emptyFields' && derogationForm.advice.length < 1" class="error">{{
                $t('EditDerogationForm.empty-fields') }}</p>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditDerogationForm.text') }}:</h4>
            <textarea v-model="derogationForm.text"></textarea>
            <p v-if="errorType === 'emptyFields' && derogationForm.text.length < 1" class="error">{{
                $t('EditDerogationForm.empty-fields') }}</p>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditDerogationForm.risk-level') }}:</h4>
            <Dropdown v-model="derogationForm.risk_color" :options="colors" optionLabel="label" optionValue="value"
                :placeholder="$t('EditDerogationForm.select-risk-level')">

            </Dropdown>
            <p v-if="errorType === 'emptyFields' && !derogationForm.risk_color" class="error">{{
                $t('EditDerogationForm.empty-fields') }}</p>
        </div>

        <div class="buttons-field">
            <Button :label="$t('EditDerogationForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text
                style="margin-right: 10px;" />
            <Button :label="$t('EditDerogationForm.save')" type="submit" icon="pi pi-check" autofocus />
        </div>
    </form>


</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';

import actions from '@/assets/JSON/actions.json';
import axiosInstance from '@/services/interceptor';
import { formatDate } from '@/helpers/helpers';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';

const { t } = i18n.global;

const props = defineProps({
    derogation: Object,
    dossier: Object
})

const emit = defineEmits(['closeForm', 'derogationEdited'])

const errorType = ref(null);

const colors = [
    { value: 'red', label: t("EditDerogationForm.high"), level: "High" },
    { value: 'orange', label: t("EditDerogationForm.medium"), level: "Medium" },
    { value: 'green', label: t("EditDerogationForm.low"), level: "Low" },
    { value: 'black', label: t("EditDerogationForm.nvt"), level: "NVT" },
]

const derogationForm = ref({
    "id": props.derogation.id || null,
    "dossier": props.derogation.dossier || null,
    "name": props.derogation.name || null,
    "text": props.derogation.text || null,
    "advice": props.derogation.advice || null,
    "risk_level": props.derogation.risk_level || null,
    "risk_color": props.derogation.risk_color || null,
})

const originalDerogation = ref({ ...props.derogation })

async function handleFormSubmission() {
    // if the originalDerogation is the same as the derogationForm, then there were no changes, so we can close the form
    // if the originalDerogation is different from the derogationForm, then the user has made changes. Therefore, we need to check if the form is valid
    // if the form is valid, we can submit the form
    // if the form is not valid, we need to show an error message
    if (originalDerogation.value.text === derogationForm.value.text &&
        originalDerogation.value.advice === derogationForm.value.advice &&
        originalDerogation.value.risk_color === derogationForm.value.risk_color
    ) {
        emit('closeForm')
    } else {
        if (checkIfFormIsValid()) {
            // format the risk level
            await formatRiskLevel()
            // submit the form, put the derogationForm to the backend
            await putDerogation()

        } else {
            // show an error message
            showError('random error')
        }
    }
}

function checkIfFormIsValid() {
    // check if the form is valid
    devLog('checking if form is valid')
    // if the form fields aren't empty, not null, not undefined, and it is a string with more than 1 letter, then the form is valid,
    // if that is not the case, then the form is not valid and the errorType will be emptyFields
    if (derogationForm.value.advice && derogationForm.value.risk_color) {
        if (derogationForm.value.advice.length > 1) {
            return true;
        } else {
            showError('emptyFields');
            return false;
        }
    } else {

        showError('emptyFields');
        return false;
    }
}

async function putDerogation() {
    devLog('putting derogation', derogationForm.value)
    // the .changed property of derogationForm has to be set to true
    derogationForm.value.changed = true;
    // put the derogationForm to the backend
    axiosInstance.put(`derogations/${derogationForm.value.id}/`, derogationForm.value)
        .then((response) => {
            emit('derogationEdited', response.data)
            emit('closeForm')
        })
        .catch((error) => {
            // handle error
            devLog(error)
        })
        .then(() => {
            // always executed
        });
}

function showError(type) {
    // show an error message
    errorType.value = type;
}

async function formatRiskLevel() {
    // format the risk level
    derogationForm.value.risk_level = colors.find(color => color.value === derogationForm.value.risk_color).level
}


</script>

<style scoped>
/* FORM */
.action-form,
.action-form-empty,
.action-form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 100%;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
    margin-top: 1rem;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

.text-field {
    color: var(--main-dark-purple);
    font-style: italic;
}
</style>