import axiosInstance from "@/services/interceptor";

export function updateDossier(newDossier) {
  axiosInstance
    .put("dossier-edit/", newDossier)
    .then((response) => {
      return response;
    })
    .catch((error) => {
    });
}
