<template>
    <div class="page-wrapper">
        <div class="page-header">
            <breadcrumbs-title></breadcrumbs-title>
        </div>

        <Card class="square-card">
            <template #content>
                <form class="form" autocomplete="off" @submit.prevent="checkData(newUser)">

                    <div class="form-field">
                        <h4 class="form-title">{{ $t('addUsersView.email') }}</h4>
                        <textarea autocomplete="off" type="text" v-model="newUser.email"></textarea>
                        <p v-if="emailIsValid === false" class="error">{{ $t('addUsersView.wrongEmail') }}</p>
                    </div>
                    <the-button class="form-button">
                        <template #text>{{ $t('addUsersView.send') }}</template>
                    </the-button>
                    <p v-if="successfulPost === true || successfulPost === false"
                        :class="{ success: successfulPost, error: !successfulPost }">{{ postResponse }}</p>
                </form>
            </template>

        </Card>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import TheButton from '@/components/ui/TheButton.vue';
import Card from 'primevue/card';

import { ref } from 'vue';
import { callWithErrorHandling } from 'vue';
import i18n from '@/i18n';

import axiosInstance from '@/services/interceptor';
import axios from 'axios';


const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/

const newUser = ref({
    "email": '',
})

const emailIsValid = ref(null);
const successfulPost = ref(null);
const postResponse = ref('')
const isLoading = ref(false)
const uninterceptedAxiosInstance = axios.create();
const BASE_URL = import.meta.env.VITE_APP_BASE_URL;
const { t } = i18n.global;


function postUser(userObject) {
    axiosInstance.post('/users/', userObject)
        .then((response) => {
            handleResponse(response.data, userObject)
        })
        .catch(() => {
            isLoading.value = false;
            successfulPost.value = false;
            postResponse.value = t('addUsersView.error')
        })
        .then(() => {
        })
}

function checkData(newUserData) {
    if (newUserData.email.match(emailRegex)) {
        emailIsValid.value = true;
    } else {
        emailIsValid.value = false;
    }

    if (emailIsValid.value === false) {
        return
    } else {
        isLoading.value = true;
        postUser(newUserData);
    }
}

function handleResponse(responseData, userObject) {
    if (responseData.success === true) {
        sendPasswordResetEmail(userObject);

    } else {
        successfulPost.value = false;
        postResponse.value = responseData.message;
        isLoading.value = false;
    }
}

function sendPasswordResetEmail(userObject) {
    axiosInstance.post(BASE_URL + 'auth/password_reset/', userObject)
        .then((response) => {
            successfulPost.value = true;
            postResponse.value = t('addUsersView.successUser');
            isLoading.value = false;
            newUser.value = {
                "email": ""
            }
        })
        .catch((error) => {
            isLoading.value = false;
            successfulPost.value = false;
            postResponse.value = t('addUsersView.error');
        })
        .then(() => {
        })
}

</script>

<style scoped>
.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 1.5rem;
}

.square-card {
    border-radius: 0;
    min-width: fit-content;
}

/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    max-width: 35rem;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

.success {
    color: green;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}
</style>