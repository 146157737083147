<template>

    <RightClickRisksFunctionality :data="rightClickData" :event="rightClickEvent" :objectType="'derogation'" >
    </RightClickRisksFunctionality>


    <Dialog v-model:visible="dialogIsVisible" modal :header="$t('DerogationsCard.popup-header')"
        :style="{ width: '75vw' }">

        <EditDerogationForm :derogation="derogationSelectedForEdit" :dossier="props.dossier"
            @closeForm="dialogIsVisible = false" @derogation-edited="derogationEdited"></EditDerogationForm>
    </Dialog>


    <Card class="card" v-if="props.selectedItems.length >= 1 && props.derogations.length > 0" :disabled="isLoading">
        <template #title>
            <div class="card-title-field">
                <h4 class="title">
                    {{ $t('DerogationsCard.derogations') }}
                </h4>
                <!-- <Button icon="pi pi-sync" class="edit" size="small" @click="startGeneratingNewDerogations()"
                    :title="$t('DerogationsCard.update-derogation')" :disabled="isLoading" /> -->


            </div>
            <i v-if="!isLoading" :class="iconClass" class="toggle-icon" style="font-size: 1.5rem"
                @click="toggleVisibility"></i>
        </template>

        <template #content v-if="showDerogations">


            <div v-for="derogation in props.derogations" :style="`--risk-color: ${derogation.risk_color}`"
                @contextmenu.prevent="rightClick($event, derogation)">
                <Panel :header="derogation.advice">
                    <template #icons>
                        <button class="p-panel-header-icon p-link mr-2" @click="showEditDerogationPopup(derogation)">
                            <span class="pi pi-pencil"></span>
                        </button>
                        <button class="p-panel-header-icon p-link mr-2"
                            @click="showDeleteDerogationConfirm(derogation)">
                            <span class="pi pi-trash"></span>
                        </button>
                    </template>
                    <p>{{ derogation.text }}</p>
                </Panel>
            </div>
        </template>

    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import Button from 'primevue/button';
import Panel from 'primevue/panel';
import Dialog from 'primevue/dialog';
import EditDerogationForm from './derogations-card/EditDerogationForm.vue';
import RightClickRisksFunctionality from '@/components/functional/RightClickRisksFunctionality.vue';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import { ref, computed } from 'vue';

import axiosInstance from '@/services/interceptor';
import i18n from '@/i18n';

const { t } = i18n.global;
const confirm = useConfirm();
const toast = useToast();

const props = defineProps({
    derogations: Array,
    selectedItems: Array,
    dossier: Object,
    dossierId: Number
})

const emit = defineEmits(['derogationEdited', 'derogationDeleted'])

const iconClass = ref('pi pi-plus');
const isLoading = ref(false);
const dialogIsVisible = ref(false);
const derogationSelectedForEdit = ref(null);

const rightClickData = ref(null);
const rightClickEvent = ref(null);

// computed properties
const syncButtonActive = computed(() => {
    // return props.selectedItems.length >= 1;
});

const showDerogations = computed(() => {
    // if props derogations is not empty return true
    // if isLoading is false return true
    // if the current iconClass is pi pi-minus return true
    return props.derogations.length > 0 && !isLoading.value && iconClass.value === 'pi pi-minus';
});

// RIGHT CLICK: sets data in variable
function rightClick(event, data) {
    rightClickData.value = data;
    rightClickEvent.value = event;
}

function toggleVisibility() {
    if (iconClass.value === 'pi pi-plus') {
        iconClass.value = 'pi pi-minus';
    } else {
        iconClass.value = 'pi pi-plus';
    }
}

function showEditDerogationPopup(derogation) {
    derogationSelectedForEdit.value = derogation;
    dialogIsVisible.value = true;
}

function derogationEdited(derogation) {
    emit('derogationEdited', derogation);
}

// delete derogation
function showDeleteDerogationConfirm(derogation) {
    confirm.require({
        message: t('DerogationsCard.confirm.message'),
        header: t('DerogationsCard.confirm.header'),
        icon: 'pi pi-exclamation-triangle',
        rejectProps: {
            label: t('DerogationsCard.confirm.reject-label'),
            severity: 'secondary',
            outlined: true
        },
        acceptProps: {
            label: t('DerogationsCard.confirm.accept-label')
        },
        rejectLabel: t('DerogationsCard.confirm.reject-label'),
        acceptLabel: t('DerogationsCard.confirm.accept-label'),
        accept: () => {
            deleteDerogation(derogation);
        },
        reject: () => {
            toast.add({ severity: 'error', summary: t('DerogationsCard.confirm.reject-summary'), detail: t('DerogationsCard.confirm.reject-detail'), life: 3000 });
        }
    });
}

function deleteDerogation(derogation) {
    // axios request to delete the derogation, and on success emit the derogationDeleted event
    axiosInstance.delete(`derogations/${derogation.id}/`)
        .then((response) => {
            toast.add({ severity: 'info', summary: t('DerogationsCard.confirm.accept-summary'), detail: t('DerogationsCard.confirm.accept-detail'), life: 3000 });
            emit('derogationDeleted', derogation);
        })
        .catch((error) => {
            // handle error
            devLog(error)
        })
        .then(() => {
            // always executed
        });
};

</script>

<style scoped>
.card {
    margin-bottom: 0.5rem;
}

:deep(.p-card-title) {
    font-size: 18px;
    font-weight: 100;
    font-family: 'Source Sans Pro', sans-serif;
    color: var(--main-dark-purple);
    text-align: start;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 1rem;
    margin-right: 1rem;
}


:deep(.p-card-content) {
    text-align: start;
    padding: 1rem;
}

:deep(.p-card-title) i:hover {
    cursor: pointer;
    color: #6D76B4;
}

:deep(.p-card-title) i:hover {
    cursor: pointer;
    color: #6D76B4;
}

::v-deep(.p-panel-header) {
    color: var(--risk-color);
}

:deep(.p-panel) {
    margin-bottom: 1rem;
}

.card-title-field {
    display: flex;
    align-items: center;
    gap: 32px;
}

.disabled-derogations {
    background-color: var(--main-light-purple);
}

.disabled-derogations:hover {
    cursor: default;
}

.disabled-derogations h4 {
    color: var(--sec-light-purple);
}
</style>