<template>

    <Dialog v-model:visible="dialogIsVisible" modal :header="$t('WarningsCard.popup-header')"
        :style="{ width: '75vw' }">

        <EditWarningForm :warning="warningSelectedForEdit" :dossier="props.dossier" @closeForm="dialogIsVisible = false"
            @warning-edited="warningEdited"></EditWarningForm>
    </Dialog>

    <form class="action-form" @submit.prevent="handleFormSubmission">

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditDiscrepancyForm.advice') }}:</h4>
            <textarea v-model="discrepancyForm.advice"></textarea>
            <p v-if="errorType === 'emptyFields' && discrepancyForm.advice.length < 1" class="error">{{
                $t('EditDiscrepancyForm.empty-fields') }}</p>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditDiscrepancyForm.text') }}:</h4>
            <textarea v-model="discrepancyForm.text"></textarea>
            <p v-if="errorType === 'emptyFields' && discrepancyForm.text.length < 1" class="error">{{
                $t('EditDiscrepancyForm.empty-fields') }}</p>
        </div>

        <div class="form-field">
            <h4 class="form-title">{{ $t('EditDiscrepancyForm.risk-level') }}:</h4>
            <Dropdown v-model="discrepancyForm.risk_color" :options="colors" optionLabel="label" optionValue="value"
                :placeholder="$t('EditDiscrepancyForm.select-risk-level')">

            </Dropdown>
            <p v-if="errorType === 'emptyFields' && !discrepancyForm.risk_color" class="error">{{
                $t('EditDiscrepancyForm.empty-fields') }}</p>
        </div>

        <div class="buttons-field">
            <Button :label="$t('EditDiscrepancyForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text
                style="margin-right: 10px;" />
            <Button :label="$t('EditDiscrepancyForm.save')" type="submit" icon="pi pi-check" autofocus />
        </div>
    </form>


</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Checkbox from 'primevue/checkbox';

import actions from '@/assets/JSON/actions.json';
import axiosInstance from '@/services/interceptor';
import { formatDate } from '@/helpers/helpers';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';

const { t } = i18n.global;

const props = defineProps({
    discrepancy: Object,
    dossier: Object
})

const emit = defineEmits(['closeForm', 'discrepancyEdited'])

const errorType = ref(null);

const colors = [
    { value: 'red', label: t("EditDiscrepancyForm.high"), level: "High" },
    { value: 'orange', label: t("EditDiscrepancyForm.medium"), level: "Medium" },
    { value: 'green', label: t("EditDiscrepancyForm.low"), level: "Low" },
    { value: 'black', label: t("EditDiscrepancyForm.nvt"), level: "NVT" },
]

const discrepancyForm = ref({
    "id": props.discrepancy.id || null,
    "dossier": props.discrepancy.dossier || null,
    "name": props.discrepancy.name || null,
    "text": props.discrepancy.text || null,
    "advice": props.discrepancy.advice || null,
    "risk_level": props.discrepancy.risk_level || null,
    "risk_color": props.discrepancy.risk_color || null,
})

const originalDiscrepancy = ref({ ...props.discrepancy })

async function handleFormSubmission() {
    // if the originalDiscrepancy is the same as the discrepancyForm, then there were no changes, so we can close the form
    // if the originalDiscrepancy is different from the discrepancyForm, then the user has made changes. Therefore, we need to check if the form is valid
    // if the form is valid, we can submit the form
    // if the form is not valid, we need to show an error message
    if (originalDiscrepancy.value.text === discrepancyForm.value.text &&
        originalDiscrepancy.value.advice === discrepancyForm.value.advice &&
        originalDiscrepancy.value.risk_color === discrepancyForm.value.risk_color
    ) {
        emit('closeForm')
    } else {
        if (checkIfFormIsValid()) {
            // format the risk level
            await formatRiskLevel()
            // submit the form, put the discrepancyForm to the backend
            await putDiscrepancy()

        } else {
            // show an error message
            showError('random error')
        }
    }
}

function checkIfFormIsValid() {
    // check if the form is valid
    devLog('checking if form is valid')
    // if the form fields aren't empty, not null, not undefined, and it is a string with more than 1 letter, then the form is valid,
    // if that is not the case, then the form is not valid and the errorType will be emptyFields
    if (discrepancyForm.value.advice && discrepancyForm.value.risk_color) {
        if (discrepancyForm.value.advice.length > 1) {
            return true;
        } else {
            showError('emptyFields');
            return false;
        }
    } else {

        showError('emptyFields');
        return false;
    }
}

async function putDiscrepancy() {
    devLog('putting discrepancy', discrepancyForm.value)
    // the .changed property of discrepancyForm has to be set to true
    discrepancyForm.value.changed = true;
    // put the discrepancyForm to the backend
    axiosInstance.put(`discrepancies/${discrepancyForm.value.id}/`, discrepancyForm.value)
        .then((response) => {
            emit('discrepancyEdited', response.data)
            emit('closeForm')
        })
        .catch((error) => {
            // handle error
            devLog(error)
        })
        .then(() => {
            // always executed
        });
}

function showError(type) {
    // show an error message
    errorType.value = type;
}

async function formatRiskLevel() {
    // format the risk level
    discrepancyForm.value.risk_level = colors.find(color => color.value === discrepancyForm.value.risk_color).level
}


</script>

<style scoped>
/* FORM */
.action-form,
.action-form-empty,
.action-form-undefined {
    color: #1A224C;
    padding: 0.5rem;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
    width: 100%;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-button {
    margin-top: 1rem;
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
    margin-top: 1rem;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

.text-field {
    color: var(--main-dark-purple);
    font-style: italic;
}
</style>