<template>
    <Dialog v-model:visible="dialogIsVisible" modal :header="$t('AssistanceButton.assistance')"
        :style="{ width: '75vw' }">
        <LawAndAssistance :legal="legal" :explanation="explanation" @closeForm="dialogIsVisible = false">
        </LawAndAssistance>
    </Dialog>

    <Button class="assistance-button" @click="showAssistance">
        <i class="pi pi-info-circle"></i>
        <p>{{ $t('AssistanceButton.assistance') }}</p>
    </Button>
</template>

<script setup>
import Button from 'primevue/button';
import LawAndAssistance from '@/components/functional/right-click-risks-functionality/LawAndAssistance.vue';
import Dialog from 'primevue/dialog';

import { ref } from 'vue';
import { useRoute } from 'vue-router';
import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';

const route = useRoute();

const props = defineProps({
    riskName: String
});

const dialogIsVisible = ref(false);
const legal = ref();
const explanation = ref();

function showAssistance() {
    // get the language from localStorage and set it to all caps
    const language = (localStorage.getItem('language') ?? 'NL').toUpperCase();
    axiosInstance.get('/advice/?dossier=' + route.params.dossierId + '&risk_name=' + props.riskName + '&language=' + language)
        .then((response) => {
            devLog(response.data)
            legal.value = response.data.data.legal;
            explanation.value = response.data.data.explanation;
            devLog('legal', legal.value, 'explanation', explanation.value);
            dialogIsVisible.value = true;

        })
        .catch((error) => {
            devLog(error)
        })
}
</script>

<style scoped>
.assistance-button i {
    margin-right: 0.5rem;
}

.assistance-button {
    background-color: var(--main-light-purple);
    color: var(--main-dark-purple);
    display: flex;
    border-radius: 5px;
    padding: 0.5rem;
    position: absolute;
    right: 0;
    margin-right: 2rem;
}

.assistance-button:hover {
    background-color: var(--sec-light-purple);
    color: white;
}
</style>