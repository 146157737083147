<template>
    <Card class="square-card">
        <template #title>
            {{ $t('ExcelExportRisksForm.title') }}
        </template>
        <template #content>
            <form class="form" @submit.prevent="checkForErrors()">

                <div class="form-field">
                    <label class="form-title" for="filename">{{ $t('ExcelExportRisksForm.name') }}:</label>
                    <InputText id="filename" v-model="exportForm.name" aria-describedby="filename-help" />
                    <p v-if="exportForm.name.length < 1 && attemptedFormSent" class="error">{{
                $t('ExcelExportRisksForm.name-explanation') }}</p>
                </div>

                <div class="form-field" id="documents" v-if="documentsAreVisible">
                    <label class="form-title" for="documents">{{ $t('ExcelExportRisksForm.pick-docs') }}:</label>
                    <MultiSelectDocuments @updateData="updateDocs" @noDocumentsAvailable="noDocumentsFound">
                    </MultiSelectDocuments>
                </div>


                <div class="form-field" id="documents" v-if="documentsAreVisible">
                    <label class="form-title" for="documents">{{ $t('ExcelExportRisksForm.selection') }}:</label>
                    <SelectionsDropdown @chosen-selection-changed="updateSelection" :hideDefault="true">
                    </SelectionsDropdown>
                </div>

                <div class="form-field">
                    <label class="form-title" for="filename">{{ $t('ExcelExportRisksForm.selection-tabs') }}:</label>

                    <div v-for="option in sectionOptions" class="form-field-flex">
                        <div class="checkbox-item">
                            <Checkbox v-model="exportForm.section" :inputId="option.value" :name="option.value"
                                :value="option.value" />
                            <label :for="option.value" class="ml-2"> {{ $t(option.label) }}: </label>
                        </div>
                        <p class="explanation">{{ $t(option.explanation) }}</p>
                    </div>
                </div>


                <TheButton class="form-button">
                    <template #text> {{ $t('ExcelExportRisksForm.title') }}</template>
                </TheButton>



            </form>

        </template>
    </Card>
</template>

<script setup>
import Card from 'primevue/card';
import TheButton from '@/components/ui/TheButton.vue';
import InputText from 'primevue/inputtext';
import MultiSelectDocuments from '@/components/functional/MultiSelectDocuments.vue';
import Checkbox from 'primevue/checkbox';
import SelectionsDropdown from '@/components/functional/SelectionsDropdown.vue';
import { useRoute } from 'vue-router';
import { ref } from 'vue';

import axiosInstance from '@/services/interceptor';
import { devLog } from '@/helpers/helpers';
import i18n from '@/i18n';

const { t } = i18n.global;

const route = useRoute();

const exportForm = ref({
    documents: [],
    section: [],
    name: '',
    selection: null
})

const attemptedFormSent = ref(false);
const documentsAreVisible = ref(true);

// all items used in the checkboxes
const sectionOptions = [
    // { label: 'ExcelExportRisksForm.info', value: "info", explanation: 'ExcelExportRisksForm.info-explanation' },
    { label: 'ExcelExportRisksForm.summaries', value: "summaries", explanation: 'ExcelExportRisksForm.summaries-explanation' },
    { label: 'ExcelExportRisksForm.analysis', value: "warnings", explanation: 'ExcelExportRisksForm.analysis-explanation' },
    { label: 'ExcelExportRisksForm.view-tender', value: "tender", explanation: 'ExcelExportRisksForm.view-tender-explanation' },
    { label: 'ExcelExportRisksForm.actions', value: "actions", explanation: 'ExcelExportRisksForm.actions-explanation' },
];

function updateDocs(newDocs) {
    exportForm.value.documents = newDocs;
};

function noDocumentsFound() {
    documentsAreVisible.value = false;
};

function updateSelection(chosenSelection) {
    if (chosenSelection !== undefined) {

        if (chosenSelection.id === 0) {
            // if standard publican id was chosen, set it to null, this value doesn't need to get passed to endpoint
            exportForm.value.selection = null;
        } else {
            exportForm.value.selection = chosenSelection.id
        }
    }
}

function checkForErrors() {
    attemptedFormSent.value = true
    if (exportForm.value.name.length < 1) {
        return
    }
    createExportURL();
};

function createExportURL() {
    const language = localStorage.getItem('language') || 'nl';
    // localhost:8000/api/report-export/?dossier=287&document[]=231&document[]=232&info=true&actions=true
    let url = "report-export/?dossier=" + route.params.dossierId + '&language=' + language;
    // adds document id's
    for (const index in exportForm.value.documents) {
        url = url + '&document[]=' + exportForm.value.documents[index];
    }

    devLog(exportForm.value.section)

    // if a string in the const sectionOptions and in the exportForm.value.section, add it to the url with value true
    // if not, add it with value false
    for (const option of sectionOptions) {
        if (exportForm.value.section.includes(option.value)) {
            url = url + '&' + option.value + '=true';
        } else {
            url = url + '&' + option.value + '=false';
        }
    }

    // if the exportForm.value.selection is not null, add it to the url
    if (exportForm.value.selection !== null) {
        url = url + '&summary=' + exportForm.value.selection;
    }

    exportRisks(url);
}


function exportRisks(exportURL) {
    devLog(exportURL);

    axiosInstance.get(exportURL, {
        responseType: "blob"
    })
        .then((response) => {
            startCSVDownload(response.data);
        })
        .catch((error) => {
            //handle error
            devLog(error)
        })
        .then(() => {
            //always executed
        });
};

function startCSVDownload(csvText) {

    const blob = new Blob([csvText], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" })

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    // name of downloaded file
    a.download = exportForm.value.name + '.xlsx';
    a.href = url;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    a.remove();
    URL.revokeObjectURL(url);

};

</script>

<style scoped>
/* FORM */
.form,
.form-empty,
.form-undefined {
    color: #1A224C;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: start;
}

.form-field {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
}

.form-button {
    margin-top: 1rem;
}

.form-title {
    font-weight: 450;
    color: var(--sec-dark-purple);
}

.error {
    color: red;
}

textarea {
    width: 100%;
    padding: 0.75rem;
    outline: none;
    background-color: white;
    border: 1px solid #6D76B4;
    color: var(--main-dark-purple);
    overflow-y: scroll;
}

.buttons-field {
    align-items: flex-end;
}

.selected-text {
    font-style: oblique;
    color: var(--main-dark-purple);
}

#documents {
    width: 100%;
    margin-top: 1rem;
    color: var(--sec-dark-purple);
}

.button-field {
    display: flex;
    gap: 15px;
}

.form-field-flex {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    margin-top: 0.5rem;
}

.square-card {
    max-width: 50%;
}
</style>
