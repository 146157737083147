<template>
	<div class="login-zone">
		<Card class="card">
			<template #content>
				<form @submit.prevent="handleLogin">
					<div class="form-field">
						<div class="p-float-label">
							<InputText id="email" v-model="email" @blur="validateEmail" class="input-field" />
							<label for="email">{{ $t('AuthLoginForm.email') }}</label>
						</div>
						<small v-if="emailError" class="p-error">{{ emailError }}</small>
					</div>

					<div class="form-field">
						<Password id="password" @blur="validatePassword" v-model="password" toggleMask
							class="p-password w-full" :feedback="false" :placeholder="$t('AuthLoginForm.password')"
							styleClass=" p-password p-component p-inputwrapper p-input-icon-right"
							:style="{ 'width': '100%' }" :inputStyle="{ 'width': '100%' }" />

						<small v-if="passwordError" class="p-error">{{ passwordError }}</small>
					</div>
					<div class="form-field">

						<Button class="standard-button" :label="$t('AuthLoginForm.signIn')" type="submit" :disabled="!isFormValid"
							:loading="loading" />
						<small v-if="loginResponse.success === false" class="p-error">{{ loginResponse.message
							}}</small>

					</div>
				</form>

				<router-link to="/forgot-password">{{ $t('AuthLoginForm.forgotPassword') }}</router-link>


				<Divider align="center" type="solid">
					<b>{{ $t('AuthLoginForm.or') }}</b>
				</Divider>
				<div class="buttons-field">
					<Button class="button-with-icon" @click="getURLMicrosoftSSO">
						<div class="logo-wrapper">

							<MicrosoftIcon :className="'microsoft-logo'" />
							<p> {{ $t('AuthLoginForm.microsoft') }}
							</p>
						</div>
					</Button>
				</div>

			</template>
		</Card>
	</div>
</template>

<script setup>
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Divider from 'primevue/divider'

import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import i18n from '@/i18n';
import MicrosoftIcon from '@/assets/icons/MicrosoftIcon.vue';


const store = useStore();
const router = useRouter();
const { t } = i18n.global;

const email = ref('');
const password = ref('');
const emailError = ref('');
const passwordError = ref('');
const isFormValid = ref(false);
const failedLogin = ref(false);
const loading = ref(false);
const loginResponse = ref({
	success: null,
	message: '',
});

const validateEmail = () => {
	const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	if (!email.value.match(emailPattern)) {
		emailError.value = t('AuthLoginForm.enterValidEmail');
		isFormValid.value = false;
	} else {
		emailError.value = '';
		validateForm();
	}
};

const validatePassword = () => {
	if (password.value.length < 3) {
		passwordError.value = t('AuthLoginForm.minPassword');
		isFormValid.value = false;
	} else {
		passwordError.value = '';
		validateForm();
	}
};

const validateForm = () => {
	if (!emailError.value && !passwordError.value) {
		isFormValid.value = true;
	}
};

async function handleLogin() {
	loading.value = true;
	// Handle login logic here
	const actionPayload = {
		email: email.value,
		password: password.value,
	};
	try {
		const response = await store.dispatch('sendLoginCredentials', actionPayload);
		// Handle successful login
		if (response && response.success) {
			router.push('/auth/mfa'); // Redirect to MFA page or another page
		}
	} catch (error) {
		// Handle failed login
		// // show user they entered wrong credentials
		// loginResponse.value = {
		//   success: false,
		//   message: error.message,
		// };

		loginResponse.value = {
			success: false,
			message: t('AuthLoginForm.invalidLogin'),
		};
	}
	failedLogin.value = !store.getters.isAuthenticated;
	loading.value = false;

};


function getCookie(name) {
	let cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			if (cookie.substring(0, name.length + 1) === (name + '=')) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}


// Function to initiate the SSO login process
async function getURLMicrosoftSSO() {
	try {
		const csrftoken = getCookie('csrftoken');

		const response = await axiosInstance.get('/auth/microsoft-url/', {
			headers: {
				'Content-Type': 'application/json',
				'X-CSRFToken': csrftoken,
			},
			withCredentials: true,  // Ensure cookies are sent with the request
		});

		if (response.status !== 200) {
			throw new Error('Network response was not ok');
		}

		const data = response.data;

		// Check if auth_url is present in the response data
		if (data.auth_url) {
			// Redirect the user to the auth_url provided by the server
			window.location.href = data.auth_url;
		} else {
			throw new Error('auth_url not found in response data');
		}
	} catch (error) {
		console.error('Login failed:', error);
	}
}

</script>

<style scoped>
.p-error {
	color: red;
	font-size: 0.875rem;
}

.card {
	flex-grow: 1;
	max-width: 750px;
}

.login-zone {
	display: flex;
	justify-content: center;
}

.form-field {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-bottom: 1rem;
}

.p-inputtext,
.form-field,
.p-password-input {
	width: 100%;
}

small {
	margin-top: 0.5rem;
}

.button-with-icon {
  background-color: transparent; /* Initial background color */
  transition: background-color 0.3s; /* Smooth transition */
  border-color: rgb(187, 187, 187);
}

.button-with-icon:hover {
  color: black;
  background-color: rgb(187, 187, 187);
  border-color: rgb(187, 187, 187);
}

.button-with-icon img {
  margin-right: 8px; /* Adjust as needed */
}

.microsoft-logo {
  width: 20px; /* Adjust size as needed */
  height: 20px; /* Adjust size as needed */
  margin-right: 8px; /* Adjust as needed */
}

.logo-wrapper {
  display: flex;
  align-items: center;
}

.standard-button {
    background-color: var(--sec-dark-purple);
    color: white;
}

.standard-button:disabled {
    background-color: var(--sec-light-purple);
    color: white;
}

.standard-button:hover {
    background-color: var(--main-dark-purple);
    color: white;
}

</style>