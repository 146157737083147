<template>
	<div class="riskSummariesList-container">
		<div class="checkbox-component">
			<v-card class="square-card">
				<h3 class="navigation-title">{{ $t('SummaryRisksTable.checklist') }}</h3>
				<TabView v-model:activeIndex="currentlySelectedTab" v-if="props.formattedChecklist && warnings">
					<TabPanel v-for="tab in tabs" :key="tab.title" :header="tab.title">
						<div class="checklist">
							<div v-for="(value, propertyName) in tab.checkboxList" :key="propertyName">
								<h4 class="subtitle">{{ $t("riskDetailsTable." + propertyName) }}</h4>
								<div v-for="checkbox in value" :key="checkbox" class="checkbox-item">
									<input class="checkboxes" type="checkbox" :id="checkbox" :name="checkbox"
										:value="checkbox" v-model="selectedItems" @change="selectSingleItem"
										:disabled="!isRiskNamePresent(checkbox)" />
									<label for="checkboxItem">{{ $t("riskDetailsTable." + checkbox) }}
										<!-- <span v-if="riskCount(checkbox) !== 0" class="checkbox-amount">({{
											riskCount(checkbox) }})</span> -->
									</label>
								</div>
							</div>
						</div>
					</TabPanel>
				</TabView>
			</v-card>
		</div>

		<div class="table-component" v-if="props.riskSummariesList.length !== 0 || props.risksList.length !== 0">
			<div v-for="property in selectedItems">
				<div class="square-card">
					<v-card class="square-card">
						<div class="flex-area">
							<h3 class="title">{{ $t("riskDetailsTable." + property) }}</h3>
							<AssistanceButton :riskName="selectedItems[0]" />
						</div>
					</v-card>
				</div>
			</div>

			<Card v-if="showCard" :disabled="props.processing" :class="disableSummaries">
				<template #title>
					<div class="card-title-field">
						<h4>
							<i v-if="props.processing" class="pi pi-hourglass">
							</i>
							{{ $t('SummaryRisksTable.summary') }}
						</h4>

						<Button icon="pi pi-sync" class="edit" size="small" v-if="showGenerateNewSummaryButton"
							@click="startGeneratingNewSummary($event, filteredRiskSummaries)"
							:title="$t('SummaryRisksTable.update-summary')"
							:disabled="disableGenerateNewSummaryButton" />

					</div>

					<i v-if="filteredRiskSummaries.length !== 0 && !props.processing" :class="summaryIconClass"
						class="toggle-icon" style="font-size: 1.5rem" @click="toggleSummaries"></i>

				</template>

				<template #content v-if="summariesAreVisible && singleRiskSummary">
					<ScrollPanel class="scroll-panel">
						<div class="edit-mode" v-if="riskSummaryIsBeingEdited === singleRiskSummary.id">
							<form class="risk-edit-form" @submit.prevent="editConfirm($event, singleRiskSummary)">
								<v-textarea auto-grow rows="1" type="text" class="text-field"
									v-model="singleRiskSummary.text" @keydown.enter="submitForm" />
								<span class="p-buttonset">
									<Button icon="pi pi-check" class="edit" size="small" type="submit"
										:title="$t('SummaryRisksTable.save-changes')" />
									<Button icon="pi pi-times" class="delete" size="small"
										@click="discardChanges(singleRiskSummary)"
										:title="$t('SummaryRisksTable.discard-changes')" />
								</span>
							</form>
						</div>

						<div class="view-mode" v-else>
							<div class="content">
								<p style="white-space: pre-line" :id="singleRiskSummary">{{
									singleRiskSummary.text }}
								</p>
							</div>

							<span class="p-buttonset">
								<!-- <Button icon="pi pi-trash" class="delete" size="small" @click="deleteConfirm($event, risk)"
									:loading="deleteIsLoading && selectedRiskForDeletion == risk.id"
									loading-icon="pi pi-spinner pi-spin" title="Verwijder de samenvatting" /> -->
								<Button icon="pi pi-pencil" class="edit" size="small"
									@click="setFieldToEdit($event, singleRiskSummary)"
									:title="$t('SummaryRisksTable.edit-summary')" />
								<Button icon="pi pi-trash" class="delete" size="small"
									@click="deleteConfirm($event, singleRiskSummary)"
									:title="$t('SummaryRisksTable.delete-summary')"
									:loading="deleteIsLoading && selectedSummaryForDeletion == singleRiskSummary.id"
									loading-icon="pi pi-spinner pi-spin" />
							</span>
						</div>
						<div class="footer">
							<p v-if="singleRiskSummary.risk_name" class="pagenumber unselectable">
								{{ $t('riskDetailsTable.' + singleRiskSummary.risk_name) }}
							</p>
						</div>
					</ScrollPanel>
				</template>
			</Card>


			<Card v-if="showCard && (!analysisStatus.loading || !analysisStatus.topics.includes(selectedItems[0]))"
				class="square-card coloured-card">
				<template #header>
					<h4>{{ $t('SummaryRisksTable.analysis') }}</h4>
					<Button icon="pi pi-sync" class="edit" size="small" v-if="showGenerateAllButton"
						@click="showGenerateNewAllConfirmation($event, filteredRiskSummaries)"
						:title="$t('SummaryRisksTable.update-all')" :disabled="disableGenerateAllButton" />

				</template>
				<template #content>
					<WarningsCard v-if="selectedItems.length !== 0" :dossier="dossier" :warnings=filteredWarnings
						:dossierId="props.dossierId" :selected-items="selectedItems" :risks="filteredRisks"
						@warning-edited="saveLocalChangesWarning" @warnings-are-done="getWarnings"
						@warning-deleted="saveLocalDeleteWarning" @warnings-posted="addNewWarnings"
						@update-dossier="updateDossierLocally" />

					<DerogationsCard v-if="selectedItems.length !== 0" :derogations="filteredDerogations"
						:selectedItems="selectedItems" :dossier="dossier" :dossierId="props.dossierId"
						@derogation-edited="saveLocalChangesDerogation"
						@derogation-deleted="saveLocalDeleteDerogation" />

					<DiscrepanciesCard v-if="selectedItems.length !== 0" :discrepancies="filteredDiscrepancies"
						:selectedItems="selectedItems" :dossier="dossier" :dossierId="props.dossierId"
						@discrepancy-edited="saveLocalChangesDiscrepancy"
						@discrepancy-deleted="saveLocalDeleteDiscrepancy" />

					<OpportunitiesCard v-if="selectedItems.length !== 0" :opportunities="filteredOpportunities"
						:selectedItems="selectedItems" :dossier="dossier" :dossierId="props.dossierId"
						@opportunity-edited="saveLocalChangesOpportunity"
						@opportunity-deleted="saveLocalDeleteOpportunity" />



				</template>
			</Card>

			<Card v-else-if="showCard && (analysisStatus.loading || analysisStatus.topics.includes(selectedItems[0]))" class="square-card coloured-card">
				<template #header>
					<h3 class="title">{{ $t('SummaryRisksTable.analysis') }}</h3>
				</template>
				<template #content>
					<p>{{ $t('SummaryRisksTable.analysis-loading') }}</p>
				</template>
			</Card>

			<Card v-if="showCard && selectedItems.length !== 0">
				<template #title>
					<h4>{{ $t('SummaryRisksTable.source-material') }}</h4>
					<span class="button-field">
						<!-- <i class="pi pi-sync" style="font-size: 1.5rem"></i> -->
						<i :class="riskIconClass" class="toggle-icon" style="font-size: 1.5rem"
							@click="toggleSources"></i>
					</span>
				</template>

				<template #content v-if="sourcesAreVisible">
					<ListOfRisks :filtered-risks="filteredRisks" :dossier="dossier"
						:sourcesAreVisible="sourcesAreVisible" @updateRisks="updateRisks"
						@refreshSelectedItem="refreshSelectedItem" @updateRisksListOnDelete="updateRisksListOnDelete"
						@updateDossier="updateDossierLocally">
					</ListOfRisks>
				</template>
			</Card>

			<div v-else>
				<v-card class="square-card empty-card">
					<h3 class="title">{{ $t('SummaryRisksTable.select-nav-item') }}</h3>
					<p>{{ $t('SummaryRisksTable.see-risk-of-selected-item') }}</p>
				</v-card>
			</div>
		</div>
		<div v-else-if="props.riskSummariesList.length === 0 && props.risksList.length !== 0" class="table-component">
			<v-card square-card empty-card>
				<h2 class="title">{{ $t('SummaryRisksTable.summaries-not-created-yet') }}</h2>
			</v-card>

		</div>
		<div v-else class="table-component">
			<v-card class="square-card empty-card">
				<h3 class="title">{{ $t('SummaryRisksTable.select-documents-and-nav-item') }}</h3>
				<p>{{ $t('SummaryRisksTable.see-risk-of-selected-item') }}</p>
			</v-card>
		</div>
	</div>
</template>

<script setup>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Button from "primevue/button";
import ListOfRisks from "@/views/summary-risks/ListOfRisks.vue";
import ScrollPanel from "primevue/scrollpanel";
import AssistanceButton from "./AssistanceButton.vue";
import WarningsCard from "./WarningsCard.vue";
import DerogationsCard from "./DerogationsCard.vue";
import DiscrepanciesCard from "./DiscrepanciesCard.vue";
import OpportunitiesCard from "./OpportunitiesCard.vue";

import Card from "primevue/card";
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";

import { ref, computed, watch, onMounted } from "vue";
import { useRoute } from "vue-router";

import axiosInstance from "@/services/interceptor";
import riskDetailsChecklistWithSubtitles from "@/assets/JSON/riskDetailsChecklistWithSubtitles.json";
import { devLog } from "@/helpers/helpers";
import { updateDossier } from "@/helpers/axiosRequests";
import i18n from '@/i18n';

const { t } = i18n.global;

const props = defineProps({
	riskSummariesList: Array,
	risksList: Array,
	formattedChecklist: Object,
	selectedSelection: Object,
	processing: Boolean,
	dossierId: Number,
});

const confirm = useConfirm();
const toast = useToast();

const emit = defineEmits([
	"updateRisks",
	"updateRisksListOnDelete",
	"removeSummary",
	"updateSummaries",
]);

const route = useRoute();

const currentlySelectedTab = ref(0);

const tabs = ref([
	// default tabs, but real ones are in watch
	{
		title: "Precontractueel",
		checkboxList: riskDetailsChecklistWithSubtitles.precontractual,
	},
	{ title: "Contractueel", checkboxList: riskDetailsChecklistWithSubtitles.contractual },
]);

// All selected items from the checkboxes are added here, items that aren't in this array won't show up
const selectedItems = ref([]);

// all selected riskSummariesList have their id's in this array
const selectedRisks = ref([]);

const sourcesAreVisible = ref(false);
const riskIconClass = ref("pi pi-plus");
const summariesAreVisible = ref(false);
const summaryIconClass = ref("pi pi-plus");
const analysisStatus = ref({ loading: false, topics: [] });

const riskSummaryIsBeingEdited = ref();
const lastRiskSummaryText = ref();

const warnings = ref();
const derogations = ref();
const discrepancies = ref();
const opportunities = ref();
const dossier = ref();

// computed property to filter riskSummariesList
const filteredRiskSummaries = computed(() => {
	return props.riskSummariesList.filter((risk) =>
		selectedItems.value.includes(risk.risk_name)
	);
});

// computed property to filter derogations
const filteredDerogations = computed(() => {
	return derogations.value.filter((derogation) =>
		selectedItems.value.includes(derogation.name)
	);
});

// computed property to filter discrepancies
const filteredDiscrepancies = computed(() => {
	return discrepancies.value.filter((discrepancy) =>
		selectedItems.value.includes(discrepancy.name)
	);
});

// computed property to filter opportunities
const filteredOpportunities = computed(() => {
	return opportunities.value.filter((opportunity) =>
		selectedItems.value.includes(opportunity.name)
	);
});

// computed property to find a single risk from riskSummariesList
const singleRiskSummary = computed(() => {
	const foundRisk = props.riskSummariesList.find((risk) =>
		selectedItems.value.includes(risk.risk_name)
	);
	return foundRisk || null; // Return foundRisk if it exists, otherwise return null
});

const filteredRisks = computed(() => {
	if (props.risksList) {
		return props.risksList.filter((risk) => selectedItems.value.includes(risk.name));
	}
});

const showCard = computed(() => {
	return selectedItems.value.length !== 0;
});

const disableGenerateAllButton = computed(() => {
	// dossier has a property called changed_summaries, which is an array of strings
	// this array includes the risk_names of all summaries that have been changed
	// if the first warning is included in the changed_summaries array, or the changed_warnings array, return true
	let riskName = selectedItems.value[0];
	let changedWarnings = dossier.value.changed_warnings;
	return !dossier.value.changed_warnings.includes(selectedItems.value[0]);
});

const showGenerateAllButton = computed(() => {
	return !props.processing;
});

// Assuming isFirstWarningIncluded is defined as a computed property
const disableGenerateNewSummaryButton = computed(() => {
	// dossier has a property called changed_summaries, which is an array of strings
	// this array includes the risk_names of all summaries that have been changed
	// if the first warning is included in the changed_summaries array, return true
	let riskName = selectedItems.value[0];
	let changedSummaries = dossier.value.changed_summaries;
	return !dossier.value.changed_summaries.includes(selectedItems.value[0]);
});

const showGenerateNewSummaryButton = computed(() => {
	return !props.processing;
});


const filteredWarnings = computed(() => {
	if (warnings.value) {
		return warnings.value.filter((warning) => selectedItems.value.includes(warning.name));
	}
});

const riskCount = computed(() => (riskName) => {
	return props.risksList.filter((risk) => risk.name === riskName).length;
});

const disableSummaries = computed(() => {
	// if props.riskSummariesList.length === 0, return a string value of 'disabled-summaries'
	return props.processing
		? "disabled-summaries"
		: "summaries";
});

// Function to check if a risk name is present in props.riskSummariesList
const isRiskNamePresent = (riskName) => {
	return (
		props.riskSummariesList.some((risk) => risk.risk_name === riskName) ||
		props.risksList.some((risk) => risk.name === riskName) ||
		warnings.value.some((warning) => warning.name === riskName)
	);
};

onMounted(() => {
	getWarnings();
	getDossier();
	getDerogations();
	getDiscrepancies();
	getOpportunities();
});

///////////////////////////////////////////////////////// selection ///////////////////////////////////////////////////////////////
watch(
	() => props.formattedChecklist,
	(newVal) => {
		if (newVal) {
			tabs.value = [
				{ title: "Precontractueel", checkboxList: newVal.precontractual },
				{ title: "Contractueel", checkboxList: newVal.contractual },
			];
		}
	},
	{ immediate: true }
);

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////// single summaries per risk_name /////////////////////////////////////////////////
watch(
	// Watch multiple sources
	[() => selectedItems.value, () => filteredRiskSummaries.value],
	// The callback receives an array of new values and an array of old values
	(
		[newSelectedItems, newFilteredRiskSummaries],
		[oldSelectedItems, oldFilteredRiskSummaries]
	) => {
		// if the user selects a single riskName, while there are summaries but not one with the selected riskName, call getRiskSummaryOfOneRiskName
		if (
			newSelectedItems.length === 1 &&
			props.riskSummariesList.length !== 0 &&
			newFilteredRiskSummaries.length === 0
		) {
			getRiskSummaryOfOneRiskName(newSelectedItems[0]);
		}
	}
);
/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function getRiskSummaryOfOneRiskName(riskName) {
	axiosInstance
		.get("/summaries/?dossier=" + props.dossierId + "&risk_name=" + riskName)
		.then((response) => {
			// if the response data is an empty array, schedule the next call
			if (response.data.length === 0) {
				timeoutId = setTimeout(getRiskSummaryOfOneRiskName, 5000);
			} else {
			}
		})
		.catch((error) => {
			devLog(error);
		});
}

function updateDossiersSummaryStatus() {
	axiosInstance
		.put("dossiers/" + props.dossierId + "/", {
			id: props.dossierId, summary_status: "processing"
		})
		.then((response) => {
			// notify user to let them know the summary is being updated
			// start polling for new summary
			// delete current summary risk
		})
		.catch((error) => {
			devLog(error);
		});

}

// keeps only a single item in checkboxes at a time
function selectSingleItem() {
	if (selectedItems.value.length >= 2) {
		selectedItems.value.shift();
	}
	// sets selection of riskSummariesList back to empty
	selectedRisks.value = [];
}

function toggleSources() {
	sourcesAreVisible.value = !sourcesAreVisible.value;
	riskIconClass.value = sourcesAreVisible.value ? "pi pi-minus" : "pi pi-plus";
}

function toggleSummaries() {
	summariesAreVisible.value = !summariesAreVisible.value;
	summaryIconClass.value = summariesAreVisible.value ? "pi pi-minus" : "pi pi-plus";
}

// visibility of modal
const visible = ref(false);
// which item user wants to use in popup
const itemShownInPopup = ref(null);

// this refreshes all items of selected checkbox by user
// without this code, there is a bug:
// (when user deletes/edits risk, all other items that are selected and have a snippet in them, aren't visible anymore)
function refreshSelectedItem() {
	let item = selectedItems.value[0];
	selectedItems.value = [];
	setTimeout(() => {
		selectedItems.value.push(item);
	}, 100);
}

function setFieldToEdit(event, riskSummary) {
	riskSummaryIsBeingEdited.value = riskSummary.id;
	lastRiskSummaryText.value = riskSummary.text;
}

function submitForm(event) {
	event.target.form.dispatchEvent(new Event("submit", { cancelable: true }));
}

function discardChanges(item) {
	item.text = lastRiskSummaryText.value;
	riskSummaryIsBeingEdited.value = null;
}

function editConfirm(event, summary) {
	// if no changes, return
	if (lastRiskSummaryText.value === summary.text) {
		riskSummaryIsBeingEdited.value = "";
		return;
	}
	axiosInstance
		.put("summaries/" + summary.id + "/", summary)
		.then((response) => {
			// removes currently deleted tender from tenderList reactive object
			// emit('updateRisk', risk);
			// emit('refreshSelectedItem');
			handleUpdateDossier(summary.risk_name, false, 'changed_summaries');
		})
		.catch((error) => {
			//handle error
			devLog(error);
		})
		.then(() => {
			//always executed
			riskSummaryIsBeingEdited.value = "";
		});
}

function updateRisksListOnDelete(risks, riskNames) {
	emit("updateRisksListOnDelete", risks, riskNames);
}

function updateRisks(risks) {
	emit("updateRisks", risks);
}

function startGeneratingNewSummary(event, summary) {

	let newSummaryObject = {
		dossier: props.dossierId,
		"risk-name": null,
	};
	if (!summary || summary.length === 0) {
		newSummaryObject["risk-name"] = selectedItems.value[0];
	} else {
		newSummaryObject["risk-name"] = summary[0].risk_name;
	}

	// minimize card
	summariesAreVisible.value = false;
	summaryIconClass.value = "pi pi-plus";
	emit("removeSummary", summary);
	// Expects dossier and risk_name as parameters
	axiosInstance
		// dossiersWithChanges = the id of the dossier where a file was recently uploaded
		.post(
			"/summaries/",
			newSummaryObject
		)
		.then((response) => {
			handleUpdateDossier(summary[0].risk_name, true, 'changed_summaries');
			emit('updateSummaries', summary[0].risk_name);
			// updateDossiersSummaryStatus();
			// notify user to let them know the summary is being updated
			// start polling for new summary
			// delete current summary risk
		})
		.catch((error) => {
			devLog(error);
		});
}

function showGenerateNewAllConfirmation(event, data) {
	confirm.require({
		// target: event.currentTarget,
		message: t('SummaryRisksTable.message-generate-new-all'),
		icon: 'pi pi-exclamation-triangle',
		acceptLabel: t('SummaryRisksTable.yes'),
		rejectLabel: t('SummaryRisksTable.no'),
		acceptClass: 'confirm-delete',
		accept: () => {
			generateNewAll(event, data);
		},
		reject: () => {
			toast.add({ severity: 'error', summary: t('SummaryRisksTable.summary-cancel'), detail: t('SummaryRisksTable.detail-cancel'), life: 3000 });
		}
	});

};

function generateNewAll(event, data) {
	handleUpdateDossier(selectedItems.value[0], true, 'changed_warnings');

	analysisStatus.value.loading = true;
	analysisStatus.value.topics.push(selectedItems.value[0]);
	let newWarningObject = {
		dossier: props.dossierId,
		"risk-name": selectedItems.value[0],
	};
	axiosInstance
		.post(
			"/warnings/",
			newWarningObject
		)
		.then((response) => {
			// not loading anymore, so set status back to false and remove the selected riskName from the topics array
			analysisStatus.value.loading = false;
			analysisStatus.value.topics = analysisStatus.value.topics.filter((topic) => topic !== selectedItems.value[0]);
			toast.add({ severity: 'success', summary: t('SummaryRisksTable.summary-success'), detail: t('SummaryRisksTable.detail-success'), life: 3000 });
			// for a local update, just call all functions that are called onMounted
			getWarnings();
			getDerogations();
			getDiscrepancies();
			getOpportunities();
			getDossier();
		})
		.catch((error) => {
			devLog(error);
		});

}

function handleUpdateDossier(riskName, reset, property) {
	// there are 3 cases
	// 1. if reset is true, then the specified property array should be empty
	// 2. if the riskName is already in the specified property array, then return
	// 3. if the riskName is not in the specified property array, then add it to the array
	let newDossier = { ...dossier.value };
	if (reset) {
		if (newDossier[property].includes(riskName)) {
			newDossier[property] = newDossier[property].filter((summary) => summary !== riskName);
		}
	} else if (newDossier[property].includes(riskName)) {
		return;
	} else {
		newDossier[property].push(riskName);
	}
	updateDossier(newDossier);
	updateDossierLocally(newDossier);
}

function updateDossierLocally(newDossier) {
	dossier.value = newDossier;
}

function getWarnings() {
	axiosInstance
		.get("/warnings/?dossier=" + props.dossierId)
		.then((response) => {
			warnings.value = response.data;
		})
		.catch((error) => {
			devLog(error);
		});
}

function getDossier() {
	axiosInstance
		.get("dossiers/" + props.dossierId + "/")
		.then((response) => {
			dossier.value = { ...response.data };
		})
		.catch((error) => {
			devLog(error)
		})
}

function getDerogations() {
	axiosInstance
		.get("derogations/?dossier=" + props.dossierId)
		.then((response) => {
			derogations.value = response.data;
		})
		.catch((error) => {
			devLog(error);
		});
};

function getDiscrepancies() {
	axiosInstance
		.get("discrepancies/?dossier=" + props.dossierId)
		.then((response) => {
			discrepancies.value = response.data;
		})
		.catch((error) => {
			devLog(error);
		});
};

function getOpportunities() {
	axiosInstance
		.get("opportunities/?dossier=" + props.dossierId)
		.then((response) => {
			opportunities.value = response.data;
		})
		.catch((error) => {
			devLog(error);
		});
};


function checkWarningsStatus() {
	axiosInstance.get('dossiers/' + props.dossierId + '/')
		.then((response) => {
			if (response.data.warnings_status !== 'done') {
				// if the status is still processing, call getRiskSummaries again after 10 seconds
				timeoutId = setTimeout(() => {
					getRiskSummaries();
				}, 10000);
			}
		})
		.catch((error) => {
			devLog(error)
		})
}

const deleteIsLoading = ref(false);
const selectedSummaryForDeletion = ref();

function deleteConfirm(event, summary) {
	selectedSummaryForDeletion.value = summary.id;
	confirm.require({
		// target: event.currentTarget,
		message: 'Weet u zeker dat u deze samenvatting wilt verwijderen?',
		icon: 'pi pi-exclamation-triangle',
		acceptLabel: 'Ja',
		rejectLabel: 'Nee',
		acceptClass: 'confirm-delete',
		accept: () => {
			deleteIsLoading.value = true;
			deleteSummary(summary);

		},
		reject: () => {
			toast.add({ severity: 'error', summary: 'Geannuleerd', detail: 'De samenvatting werd niet verwijderd', life: 3000 });
		}
	});
}

function deleteSummary(summary) {
	axiosInstance.delete("summaries/" + summary.id + "/").then((response) => {
		toast.add({ severity: 'info', summary: 'Succes', detail: 'De samenvatting werd succesvol verwijderd.', life: 3000 });
		handleUpdateDossier(summary.risk_name, false, 'changed_summaries');
		// removes currently deleted summary from parent component's reactive object
		emit("removeSummary", summary);
		// deleteIsLoading.value = false;
	});
}

function saveLocalChangesWarning(warning) {
	// find the index of the warning in the warnings array by id
	const index = warnings.value.findIndex((w) => w.id === warning.id);
	if (index !== -1) {
		warnings.value.splice(index, 1, warning);
	}
}

function saveLocalChangesDerogation(derogation) {
	// find the index of the derogation in the derogations array by id
	const index = derogations.value.findIndex((d) => d.id === derogation.id);
	if (index !== -1) {
		derogations.value.splice(index, 1, derogation);
	}
}

function saveLocalChangesDiscrepancy(discrepancy) {
	// find the index of the discrepancy in the discrepancies array by id
	const index = discrepancies.value.findIndex((d) => d.id === discrepancy.id);
	if (index !== -1) {
		discrepancies.value.splice(index, 1, discrepancy);
	}
}

function saveLocalChangesOpportunity(opportunity) {
	// find the index of the opportunity in the opportunities array by id
	const index = opportunities.value.findIndex((o) => o.id === opportunity.id);
	if (index !== -1) {
		opportunities.value.splice(index, 1, opportunity);
	}
}

function saveLocalDeleteWarning(warning) {
	// find the index of the warning in the warnings array
	const index = warnings.value.findIndex((w) => w.id === warning.id);
	// remove the warning from the warnings array
	warnings.value.splice(index, 1);
}

function saveLocalDeleteDerogation(derogation) {
	// find the index of the derogation in the derogations array
	const index = derogations.value.findIndex((d) => d.id === derogation.id);
	// remove the derogation from the derogations array
	derogations.value.splice(index, 1);
}

function saveLocalDeleteOpportunity(opportunity) {
	const index = opportunities.value.findIndex((d) => d.id === opportunity.id);
	// remove the derogation from the derogations array
	opportunities.value.splice(index, 1);
}

function saveLocalDeleteDiscrepancy(discrepancy) {
	// find the index of the discrepancy in the discrepancies array
	const index = discrepancies.value.findIndex((d) => d.id === discrepancy.id);
	// remove the discrepancy from the discrepancies array
	discrepancies.value.splice(index, 1);
}

function addNewWarnings(newWarnings, riskName) {
	// remove all warnings with the same riskName from the warnings array
	warnings.value = warnings.value.filter((warning) => warning.name !== riskName);
	// add the new warnings to the warnings array
	warnings.value.push(...newWarnings);
}

</script>

<style scoped>
.subtitle {
	text-align: start;
	margin-left: 0.5rem;
	font-weight: 600;
	color: var(--main-dark-purple);
	margin-top: 1rem;
}

.navigation-title,
.title {
	justify-content: center;
	padding: 1rem;
	/* background-color: white; */
	color: var(--main-dark-purple);
}

.navigation-title {
	border-bottom: 1px solid var(--main-light-purple);
}

.riskSummariesList-container {
	display: flex;
}

.table-component {
	margin-left: 1rem;
	width: 50%;
	flex-grow: 2;
	overflow-y: auto;
}

.checkbox-component {
	width: 20%;
}

.menu-component {
	width: 35%;
}

.square-card {
	border-radius: 0;
	margin-bottom: 0.5rem;
}

.checkbox-tab-content {
	text-align: start;
	overflow-y: auto;
}

.checkbox-item {
	display: flex;
	padding: 0.5rem;
	vertical-align: center;
}

/* .checkbox-item:not(:disabled):hover {
  cursor: pointer;
  background-color: #E7EAFF;
} */

.explanation {
	font-size: xx-small;
}

input.checkboxes {
	width: 15px;
	height: 15px;
	position: relative;
	align-items: center;
	top: 2.5px;
}

label {
	margin-left: 0.8rem;
	text-align: start;
}

input.checkboxes:disabled {
	background: #6d76b4;
}

input.checkboxes:disabled+label {
	color: var(--main-light-purple);
	font-weight: 200;
}

.title-count {
	display: inline;
	font-weight: 500;
}

.riskSummariesList-container,
.menu-container {
	flex-grow: 1;
}

.risk-body {
	padding: 1rem;
	/* border-bottom: 1px solid #95A0EC; */
	text-align: start;
	/* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
	/* margin-bottom: 0.5rem; */
	height: fit-content;
	font-size: 18px;
}

.risk-body:hover {
	cursor: pointer;
}

.summary-body {
	padding: 1rem;
	/* border-bottom: 1px solid #95A0EC; */
	text-align: start;
	/* box-shadow: 0 3px 10px rgb(0 0 0 / 0.2); */
	/* margin-bottom: 0.5rem; */
	height: fit-content;
	font-size: 18px;
	/* background-color: var(--main-light-purple); */
}

.summary-body:hover {
	cursor: pointer;
}

.checkbox-amount {
	font-weight: 350;
	color: #6d76b4;
}

:deep(.p-button) {
	border-color: #1a224c;
	background-color: white;
	color: #1a224c;
	font-size: 14px;
	padding: 0.3rem;
	height: 2rem;
}

:deep(.p-button.delete:hover) {
	background-color: red;
	border-color: red;
	color: white;
}

:deep(.p-button.edit:hover) {
	background-color: green;
	border-color: green;
	color: white;
}

.view-mode {
	min-height: fit-content;
	display: flex;
	border-radius: 15px;
	padding: 0.5rem;
	padding-left: 1rem;
	justify-content: space-between;
}

.motivation {
	white-space: pre-line;
	text-align: left;
	margin-left: 100px;
}

.empty-card {
	padding-bottom: 2rem;
}

.footer {
	display: flex;
	justify-content: space-between;
	margin-top: 0.5rem;
	font-size: 14px;
	color: var(--sec-dark-purple);
}

.pagenumber {
	color: var(--sec-dark-purple);
	font-size: 10px;
}

.unselectable {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	text-align: start;
}

.checklist {
	overflow-y: auto;
	max-height: 55vh;
}

.selected {
	background-color: var(--main-light-purple);
}

.icon-header {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: end;
	align-items: center;
	justify-content: space-between;
}

.scroll-panel {
	max-height: calc(100% + 0.5rem);
	/* Adjust as needed */
	overflow-y: auto;
}

.risk-edit-form {
	display: flex;
	width: 100%;
	height: 100%;
}

.card-title-field {
	display: flex;
	align-items: center;
	gap: 32px;
}

span i:last-child {
	margin-left: 20px;
}

span i:hover {
	cursor: pointer;
	color: #6d76b4;
}

.flex-area {
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

:deep(.p-card-title) {
	font-size: 18px;
	font-weight: 100;
	font-family: "Source Sans Pro", sans-serif;
	color: var(--main-dark-purple);
	text-align: start;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 1rem;
	margin-right: 1rem;
}

:deep(.p-card-content) {
	text-align: start;
	padding: 1rem;
}

:deep(.p-card-title) i:hover {
	cursor: pointer;
	color: #6d76b4;
}

:deep(.p-card-title) i:hover {
	cursor: pointer;
	color: #6d76b4;
}

:deep(.p-card) {
	margin-bottom: 0.5rem;
	min-height: fit-content;
}

.disabled-summaries {
	background-color: var(--main-light-purple);
}

.disabled-summaries:hover {
	cursor: default;
}

.disabled-summaries h4 {
	color: var(--sec-light-purple);
}

.p-buttonset {
	min-width: fit-content;
	margin-left: 0.5rem;
}

.coloured-card {
	background-color: #FAFAFA;
}

:deep(.p-card-header) {
	background-color: white;
	font-size: 18px;
	font-weight: 100;
	font-family: "Source Sans Pro", sans-serif;
	color: var(--main-dark-purple);
	text-align: start;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 1.5rem;
	padding-top: 1.5rem;
	padding-left: 3rem;
	padding-right: 3rem;
	/* add shadow under */
	box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
}

:deep(.p-confirmdialog) {
	max-width: 500px;
}
</style>