<template>
    <div class="page-wrapper" v-if="dossierName !== null">
        <breadcrumbs-title></breadcrumbs-title>
        <!-- <TheNewButton :icon="'pi pi-search'">

        </TheNewButton> -->
        <div class="grid">
            <div class="col-8">
                <h1 class="dossier-title">{{ $t('DashboardView.project') }}: {{ dossierName }}</h1>
            </div>
            <div class="button-field">
                <span class="p-buttonset">
                    <Button type="button" :label="$t('DashboardView.files')" icon="pi pi-upload" outlined
                        @click="goToURL('/dashboard/' + route.params.dossierId + '/upload')" id="upload" />
                </span>
            </div>
        </div>
        <Card>
            <template #title>
                <div class="card-title-field">
                    <p>{{ $t('DashboardView.analysis-search') }}</p>
                    <Button type="button" :label="$t('DashboardView.reporting')" icon="pi pi-download" outlined
                        @click="goToURL('/dashboard/' + route.params.dossierId + '/export-analysis')" id="export" />
                </div>
            </template>
            <template #content>
                <div class="flex-wrapper">
                    <dashboard-analysis-risks class="flex-item"></dashboard-analysis-risks>
                    <dashboard-analysis-document class="flex-item"></dashboard-analysis-document>
                    <dashboard-search class="flex-item"></dashboard-search>
                    <dashboard-dossier-actions class="flex-item"></dashboard-dossier-actions>

                    <!-- <PDFRender class="flex-item" /> -->
                </div>
            </template>

        </Card>

        <Divider></Divider>

        <Card>
            <template #title>

                <div class="card-title-field">
                    <p>{{ $t('DashboardView.qualifications-conformity') }}</p>
                    <Button type="button" :label="$t('DashboardView.reporting')" icon="pi pi-download" outlined
                        :disabled="qualificationMatrix === 'empty' || qualificationMatrix === 'processing'"
                        @click="goToURL('/dashboard/' + route.params.dossierId + '/export-qualification')"
                        id="export" />
                </div>

            </template>
            <template #content>
                <div class="flex-wrapper">
                    <dashboard-qualification v-if="qualificationMatrix" class="flex-item"
                        :qualificationMatrix="qualificationMatrix"></dashboard-qualification>
                    <dashboard-conformity v-if="qualificationMatrix" class="flex-item"
                        :qualificationMatrix="qualificationMatrix"></dashboard-conformity>
                </div>
            </template>
        </Card>

        <Divider></Divider>

        <Card>
            <template #title>{{ $t('DashboardView.project-management') }}</template>
            <template #content>
                <div class="flex-wrapper">
                    <dashboard-dossier-information class="flex-item"></dashboard-dossier-information>
                </div>
            </template>
        </Card>

    </div>
    <div class="page-wrapper" v-else>
        <not-found-view class="not-found"></not-found-view>
    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import TheNewButton from '@/components/ui/TheNewButton.vue';

import DashboardAnalysisRisks from '@/views/dashboard/DashboardAnalysisRisks.vue';
import DashboardAnalysisDocument from '@/views/dashboard/DashboardAnalysisDocument.vue';
import DashboardSearch from '@/views/dashboard/DashboardSearch.vue';
import DashboardDossierInformation from '@/views/dashboard/DashboardDossierInformation.vue';
import DashboardDossierActions from '@/views/dashboard/DashboardDossierActions.vue';
import DashboardAIAssistance from '@/views/dashboard/DashboardAIAssistance.vue';

import DashboardConformity from "@/views/dashboard/DashboardConformity.vue";
import DashboardQualification from "@/views/dashboard/DashboardQualification.vue"
import PDFRender from '@/views/dashboard/DashboardPDFRender.vue';

import DocumentsDropdown from '@/components/functional/DocumentsDropdown.vue';

import { useRoute } from 'vue-router';
import { onMounted, ref, onBeforeUnmount } from 'vue';
import router from '@/router';

import axiosInstance from '@/services/interceptor';
import qualificationsFilter from '@/assets/JSON/qualificationsFilters';
import { devLog } from '@/helpers/helpers';

const route = useRoute();
const dossierName = ref('');
const qualificationMatrix = ref(null);
let timeoutId = null;
let amountOfQualificationMatrixGetRequests = 0;


onBeforeUnmount(() => {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }
});

onMounted(() => {
    getDossier();
    localStorage.setItem("dossierID", route.params.dossierId);
})

function getDossier() {
    axiosInstance.get('dossiers/' + route.params.dossierId + '/')
        .then((response) => {
            dossierName.value = response.data.name;
            checkForSelection(response.data.has_selection);
        })
        //handle error
        .catch((error) => {
            dossierName.value = null;
        })
        //always executed
        .then(() => {
        });
}

function goToURL(URL) {
    router.push(URL);
}

function checkForSelection(has_selection) {
    if (has_selection === true) {
        getQualificationMatrix();
    } else {
        // if there is no selection, set qualificationMatrix to 'empty'
        // we could assign it a different value in order to showcase a more specific message
        // but for now, we will just set it to 'empty'
        qualificationMatrix.value = 'empty';
    }
}

function getQualificationMatrix() {
    axiosInstance.get('qualificationMatrix/?dossier=' + route.params.dossierId)
        .then((response) => {
            // call handlePollingLogic with the response data
            handlePollingLogic(response.data)
        })
        //handle error
        .catch((error) => {
        })
        //always executed
        .then(() => {
        });
}

function handlePollingLogic(response) {
    devLog('looping for qualification matrix', response)
    amountOfQualificationMatrixGetRequests++;
    // if there is no response, then there are no qualifications
    if (amountOfQualificationMatrixGetRequests > 20) {
        qualificationMatrix.value = 'empty';
        return
    } else if (response.length === 0) {
        // STARTS A LOOP
        // so set qualificationMatrix to 'empty' and call postQualificationMatrix
        qualificationMatrix.value = 'empty';
        handleQualificationMatrixPostRequest();
    } else if (response.length >= 1 && response[0].status === 'processing' || response[0].status === 'empty') {
        // STARTS A LOOP
        // if there is a response and the status is 'processing', set qualificationMatrix to 'processing'
        qualificationMatrix.value = 'processing';
        // after 5 seconds call getQualificationMatrix again
        timeoutId = setTimeout(() => {
            getQualificationMatrix();
        }, 5000);
    } else {
        // if there is a response and the status is 'done', set qualificationMatrix to the status
        // this also stops the loop
        qualificationMatrix.value = response[0].status;
        return
    }
}

async function handleQualificationMatrixPostRequest() {
    // get qualifications
    const qualifications = await getQualifications();
    // if not all qualifications are created in the backend, return
    if (qualifications.length !== qualificationsFilter.length) {
        // after 5 seconds, call getQualificationMatrix again
        // restart the loop
        timeoutId = setTimeout(() => {
            getQualificationMatrix();
        }, 5000);
        return;
    }
    // if all qualifications are created in the backend, continue
    const documents = await getDocuments();
    // find document where .used_for_qualifications is true
    const usedForQualifications = documents.find(document => document.used_for_qualifications === true);
    // if there is no document where .used_for_qualifications is true, return
    if (!usedForQualifications || usedForQualifications === undefined || usedForQualifications === null || usedForQualifications === '' || usedForQualifications.length === 0) {
        // after 5 seconds, call getQualificationMatrix again
        timeoutId = setTimeout(() => {
            getQualificationMatrix();
        }, 5000);
        return;
    } else {
        postQualificationMatrix(usedForQualifications.id);
    }
}

async function getQualifications() {
    return axiosInstance.get('qualifications/?dossier=' + route.params.dossierId)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}

async function getDocuments() {
    return axiosInstance.get('documents/?dossier=' + route.params.dossierId)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            return error;
        });
}

async function postQualificationMatrix(docId) {
    // is only called when there are no qualifications, after being called once this should no longer be called
    axiosInstance.post('qualificationMatrix/', {
        dossier: route.params.dossierId,
        document: docId
    })
        .then((response) => {
        })
        //handle error
        .catch((error) => {
        })
        //always executed
        .then(() => {
            // after 5 seconds, call getQualificationMatrix again
            devLog('loop after post started')
            timeoutId = setTimeout(() => {
                getQualificationMatrix();
            }, 5000);
        });
}

</script>

<style scoped>
.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.flex-wrapper>* {
    flex: 1 0 33.33%;
}

.button-field {
    padding: 0;
    margin-bottom: 1rem;
    text-align: start;
    margin-left: 0.5rem;
}

.not-found {
    margin-top: 1rem;
}

.dossier-title {
    color: var(--main-dark-purple);
    margin-bottom: 1.5rem;
    text-align: start;
}

.dashboard {
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
}

.dashboard-content,
.dashboard-content-two-items {
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.section {
    width: 49%;
    margin-top: 2rem;
}

.section-two-items {
    width: 48%;
    margin-top: 2rem;

}

.full-section {
    width: 100%;
    margin-top: 2rem;
}

.section-title {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.dashboard-icon {
    fill: var(--sec-dark-purple);
    width: 35px;
    margin-right: 1rem;
}

.dashboard-info-icon {
    fill: #6C79BE;
    width: 25px;
    border: 2px solid var(--sec-dark-purple);
    border-radius: 50%;
    padding: 1px;
    margin-left: 1rem;
}

.dashboard-title {
    font-weight: 550;
}

@media (max-width: 1545px) {
    .dashboard-content {
        flex-direction: column;
    }

    .section {
        width: 100%;
    }
}

:deep(.p-tabview-panels) {
    background-color: transparent;
    padding: 0;
    margin-top: 2rem;
}


:deep(.p-tabview-nav) {
    font-style: normal !important;
    color: #1A224C;
    justify-content: center;
}

:deep(.p-tabview-title) {
    color: var(--sec-light-purple);
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 500;
}

:deep(.p-tabview-header.p-highlight .p-tabview-nav-link.p-tabview-header-action .p-tabview-title) {
    color: var(--main-dark-purple);
    border-color: var(--main-dark-purple);
}

:deep(.p-card-title) {
    text-align: start;
    font-family: var(--font-family);
    font-weight: 600;
    color: var(--main-dark-purple)
}

.grid {
    display: flex;
    flex-direction: column;
}

.card-title-field {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.card-title-field> :nth-child(2) {
    margin-left: 2rem;
    /* or 3rem depending on your preference */
}
</style>