<template>
    <div class="full-page">
        <breadcrumbs-title></breadcrumbs-title>
        <div class="wrapper" v-if="documentsAreVisible">
            <p class="wrapper-text">{{ $t('SummaryRisksView.document-list') }}:</p>
            <multi-select-documents @updateData="getRisks"
                @noDocumentsAvailable="noDocumentsFound"></multi-select-documents>
        </div>

        <selections-dropdown class="selection-dropdown" @chosenSelectionChanged="updateSelection"></selections-dropdown>

        <div class="content">
            <summary-risks-table v-if="getRiskSummariesCalled" :processing="riskSummariesAreProcessing"
                :dossierId="dossierId" :riskSummariesList="riskSummariesList" :risksList="risksList"
                :selected-selection="selectedSelection" :formattedChecklist="formattedChecklist"
                @update-risks="updateRisks" @updateRisksListOnDelete="updateRisksListOnDelete"
                @removeSummary="removeSummary" @updateSummaries="getRiskSummaries"></summary-risks-table>
        </div>

    </div>
</template>

<script setup>
import BreadcrumbsTitle from '@/components/ui/BreadcrumbsTitle.vue';
import SummaryRisksTable from '@/views/summary-risks/SummaryRisksTable.vue';
import MultiSelectDocuments from "@/components/functional/MultiSelectDocuments.vue"
import SelectionsDropdown from '@/components/functional/SelectionsDropdown.vue';

import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router';

import axiosInstance from '@/services/interceptor';
import riskDetailsChecklistWithSubtitles from '@/assets/JSON/riskDetailsChecklistWithSubtitles.json';
import { devLog } from '@/helpers/helpers.js';
import samenvattingen from '@/assets/JSON/samenvattingen.json';

const route = useRoute();

//array with all the risk details
const riskSummariesList = ref(null);
const risksList = ref([]);
const documentsAreVisible = ref(true);
const noDocumentsWereFound = ref(false);
const selectedSelection = ref();
// checklist that has been adjusted according to the selected profile in the selections dropdown
const formattedChecklist = ref();
// dossierID is needed for the getRiskSummaries function
const dossierId = Number(route.params.dossierId);

const documents = ref([]);
// timeoutId needed for the getRiskSummaries function
let timeoutId = null;

const getRiskSummariesCalled = ref(false);
const riskSummariesAreProcessing = ref(false);

onMounted(() => {
    getRiskSummaries();
});

onUnmounted(() => {
    if (timeoutId) {
        clearTimeout(timeoutId);
    }
});



function noDocumentsFound(value) {
    noDocumentsWereFound.value = value;
    documentsAreVisible.value = false;
}



//! This is the old function, where I replace the API data with mock data. I will keep it here for now, in case I need it later.
// // gets all the risk summaries for the current dossier
// function getRiskSummaries(amountOfRisks) {
//     // if the developer is in development mode and the developer role is frontend, use the mock data
//     if (import.meta.env.MODE === 'development' && import.meta.env.VITE_DEVELOPER_ROLE === 'frontend') {
//         devLog('Using mock data for risk summaries');
//         riskSummariesList.value = samenvattingen;
//     } else {
//         // This is the actual call to the backend, only used in production or by users wanting to test this feature
//         axiosInstance.get('/summaries/?dossier=' + dossierId)
//             .then((response) => {
//                 devLog('Risk summaries:', response.data);
//                 devLog('response length:', response.data.length, 'amountOfRisks:', amountOfRisks);
//                 riskSummariesList.value = response.data;
//                 // If the response data is an empty array, schedule the next call
//                 //TODO: remove hardcoded value, make it dynamic, this is just temporary
//                 if (response.data.length < amountOfRisks) {
//                     devLog('No risk summaries found or not enough, scheduling next call')
//                     // timeoutId = setTimeout(getRiskSummaries(amountOfRisks), 10000);
//                     // after 10 seconds, call getRiskSummaries again
//                     timeoutId = setTimeout(() => {
//                         getRiskSummaries(amountOfRisks);
//                     }, 10000);
//                 }
//             })
//             .catch((error) => {
//                 devLog(error)
//             })
//     }
// };


// gets all the risk summaries for the current dossier
function getRiskSummaries() {
    // This is the actual call to the backend, only used in production or by users wanting to test this feature
    axiosInstance.get('/summaries/?dossier=' + dossierId)
        .then((response) => {
            riskSummariesList.value = response.data;
            getRiskSummariesCalled.value = true;
            // call checkRiskSummariesStatus, to check if some risks are still being processed in the dossier
            checkRiskSummariesStatus();
        })
        .catch((error) => {
            devLog(error)
        })
};

function checkRiskSummariesStatus() {
    axiosInstance.get('dossiers/' + dossierId + '/')
        .then((response) => {
            if (response.data.summary_status !== 'done') {
                riskSummariesAreProcessing.value = true;
                // if the status is still processing, call getRiskSummaries again after 10 seconds
                timeoutId = setTimeout(() => {
                    getRiskSummaries();
                }, 10000);
            } else {
                riskSummariesAreProcessing.value = false;
            }
        })
        .catch((error) => {
            devLog(error)
        })
}

// when user selects other documents in multi select chips component, this function gets called by an emit
function getRisks(selectedDocuments) {
    documents.value = selectedDocuments;

    // if user doesn't select any documents
    if (selectedDocuments.length === 0) {
        risksList.value = [];
        return;
    }

    const documentParams = selectedDocuments.join('&document_params[]=');
    const url = `risks/?document_params[]=${documentParams}`;

    axiosInstance
        .get(url)
        .then((response) => {
            risksList.value = response.data;
            // if getRiskSummaries has not been called yet, call it
            getLengthOfRiskNames(response.data);
        })
        .catch((error) => {
            devLog(error);
        });
}

function getLengthOfRiskNames(risks) {
    const riskNames = risks.map(risk => risk.name);
    const uniqueRiskNames = [...new Set(riskNames)];
    getRiskSummaries(uniqueRiskNames.length);
}

// gets called on emit by RiskDetailsTable when risk is deleted or edited
function updateRisks(risks) {
    // locally update risks
    getRisks(documents.value);
    // putSummaries(risks);
}

function updateRisksListOnDelete(deletedRisks, riskNames) {
    // locally update risks
    // remove the deleted risks from the risksList
    risksList.value = risksList.value.filter(risk => !deletedRisks.includes(risk.id));
    // set the .changed property to true for the riskSummariesList items where .name === riskNames
    //riskNames is an array of names of the deleted risks
    // riskSummariesList is an array of objects with the properties id, risk_name, changed
    riskSummariesList.value.forEach(summary => {
        if (riskNames.includes(summary.risk_name)) {
            summary.changed = true;
        }
    });

}
function putSummaries(risks) {
    // for every risk in risks, get the risk.name and put it in an array
    let riskNames = risks.map(risk => risk.name);

    let updateSummariesObject = {
        dossier: dossierId,
        risk_names: riskNames
    };

    axiosInstance
        .put('/summaries/', risks)
        .then((response) => {
        })
        .catch((error) => {
            devLog(error);
        });
}

function updateSelection(newSelection) {
    selectedSelection.value = newSelection;
    // formattedChecklist.value = formatChecklist(newSelection);
    formattedChecklist.value = { ...filterChecklists(newSelection, riskDetailsChecklistWithSubtitles) };
};

// filters the checklist according to the selected profile
function filterChecklists(newSelection) {
    // Create a deep copy of riskDetailsChecklistWithSubtitles
    const checklistCopy = JSON.parse(JSON.stringify(riskDetailsChecklistWithSubtitles));

    for (const property in checklistCopy) {
        for (const title in checklistCopy[property]) {
            const array = checklistCopy[property][title];
            const intersection = array.filter(element => newSelection[property].includes(element));
            if (intersection.length > 0) {
                checklistCopy[property][title] = intersection;
            } else {
                delete checklistCopy[property][title];
            }
        }
        if (Object.keys(checklistCopy[property]).length === 0) {
            delete checklistCopy[property];
        }
    }
    return checklistCopy;
}

function removeSummary(summary) {
    riskSummariesList.value = riskSummariesList.value.filter(item => item.id !== summary.id);
    // set riskSummariesAreProcessing to true
    riskSummariesAreProcessing.value = true;
    // after 5 seconds, call getRiskSummaries
    // //TODO: add looping logic
    // setTimeout(() => {
    //     getRiskSummaries();
    // }, 5000);
}

</script>

<style scoped>
.full-page {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.button-field {
    display: flex;
    margin: 0.5rem;
    justify-content: space-between;
}

:deep(.p-tabview-panels) {
    background-color: transparent;
    padding: 0;
    margin-top: 1rem;
}

:deep(.p-tabview-nav) {
    font-style: normal !important;
    color: #1A224C;
    justify-content: center;
}

:deep(.p-tabview-title) {
    color: var(--sec-light-purple);
    font-size: 16px;
    font-family: var(--font-family);
    font-weight: 500;
}

:deep(.p-tabview-header.p-highlight .p-tabview-nav-link.p-tabview-header-action .p-tabview-title) {
    color: var(--main-dark-purple);
    border-color: var(--main-dark-purple);
}

.wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.wrapper-text {
    text-align: start;
    font-size: 14px;
    min-width: 7rem;
    margin-right: 1rem;
    margin-bottom: 1rem;
}
</style>