<template>
  <form v-if="showForm" class="risk-form" @submit.prevent="handleSubmit">

    <div class="form-field">
      <div class="form-item">
        <h4 class="form-title">{{ $t('riskForm.selectedText') }}:</h4>
        <v-textarea auto-grow rows="1" type="text" class="text-field" v-model="riskForm.section" />
      </div>
    </div>
    <p v-if="!formIsValid && riskForm.section.length === 0" class="error">{{ $t('riskForm.empty') }}</p>


    <div class="form-field">
      <div class="form-item">
        <h4 class="form-title">{{ $t('riskForm.phase') }}</h4>
        <Dropdown v-model="riskForm.chapter" :options="phases" :placeholder="t('riskForm.selectPhase')"
          class="w-full md:w-14rem">
          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div>{{ $t('addRiskForm.' + slotProps.value) }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>
          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ $t('addRiskForm.' + slotProps.option) }}</div>
            </div>
          </template>
        </Dropdown>
        <p v-if="!formIsValid && !riskForm.chapter" class="error">{{ $t('riskForm.selectDropdown') }}</p>

      </div>


      <div class="form-item">
        <h4 class="form-title">{{ $t('riskForm.name') }}</h4>

        <Dropdown v-if="!riskForm.chapter" :disabled="!riskForm.chapter" :placeholder="t('riskForm.selectPhase')"
          class="w-full md:w-14rem">
        </Dropdown>

        <Dropdown v-else-if="riskForm.chapter === 'precontractual'" v-model="riskForm.name"
          :options="riskDetailsChecklist.precontractual" :disabled="!riskForm.chapter"
          :placeholder="t('riskForm.selectPhase')" class="w-full md:w-14rem">

          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.value) }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>

          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.option) }}</div>
            </div>
          </template>

        </Dropdown>


        <Dropdown v-else-if="riskForm.chapter === 'contractual'" v-model="riskForm.name"
          :options="riskDetailsChecklist.contractual" :disabled="!riskForm.chapter"
          :placeholder="t('riskForm.selectPhase')" class="w-full md:w-14rem">

          <template #value="slotProps">
            <div v-if="slotProps.value" class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.value) }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template>

          <template #option="slotProps">
            <div class="flex align-items-center">
              <div>{{ $t('riskDetailsTable.' + slotProps.option) }}</div>
            </div>
          </template>

        </Dropdown>

        <p v-if="!formIsValid && !riskForm.name" class="error">{{ $t('riskForm.selectDropdown') }}</p>
      </div>

    </div>
    <div class="buttons-field">
      <Button :label="$t('detailedAnalysisForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text />
      <Button :label="$t('riskForm.saveRisk')" icon="pi pi-check" class="accept-button" @click="checkIfFormIsValid"
        autofocus />
    </div>
  </form>
  <div v-else class="confirmation-page">
    <div class="confirmation-field">
      <h3 v-if="!props.existingRisk" class="confirmation-title">{{ $t('riskForm.addSuccess') }}</h3>
      <h3 v-else class="confirmation-title">{{ $t('riskForm.editSuccess') }}</h3>
      <p class="confirmation-item">{{ $t('riskForm.addAction') }}</p>
    </div>
    <div class="buttons-field entire-page">
      <Button :label="t('riskForm.cancel')" icon="pi pi-times" @click="emit('closeForm')" text />

      <Button :label="t('riskForm.accept')" class="accept-button" icon="pi pi-check"
        @click="emit('formFinished', newlyCreatedRiskID)" autofocus />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { useRoute } from 'vue-router';

import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';

import axiosInstance from '@/services/interceptor';
import riskDetailsChecklist from "@/assets/JSON/riskDetailsChecklist.json"
import i18n from '@/i18n';
import { devLog } from '@/helpers/helpers';


const props = defineProps({
  selectedText: String,
  documentID: Number,
  paragraphID: Number,
  existingRisk: Object,
})

const { t } = i18n.global;

const emit = defineEmits(['closeForm', 'formFinished', 'riskAdded', 'riskEdited']);
const route = useRoute();

onMounted(() => {
  checkIfRiskExists();
})

const showForm = ref(true);
const newlyCreatedRiskID = ref(null);
const phases = [
  'precontractual',
  'contractual'
]

const riskForm = ref({
  "name": '',
  "chapter": null,
  "document": props.documentID,
  "section": props.selectedText,
  "dossier": parseInt(route.params.dossierId),
  "paragraph_id": props.paragraphID
});

const formIsValid = ref(true);

function checkIfRiskExists() {
  if (props.existingRisk) {
    // using a deep clone instead of assigning it to the form
    // because all changes made to the form, would also change the existingRisk object
    // which will in turn be used to update the risk, even when the user doesn't want to update it
    riskForm.value = JSON.parse(JSON.stringify(props.existingRisk));
  }
}

function checkIfFormIsValid() {
  if (riskForm.value.name.length < 1 || riskForm.value.name === null) {
    formIsValid.value = false;
    return
  } else {
    // if there is no existing risk passed, then create new risk
    if (!props.existingRisk) {
      createRisk();
      // update existing risk that was passed down
    } else {
      putRisk();
    }
  }
}

function createRisk() {
  devLog(riskForm.value)
  // first create a risk with data here, and then add action to newly created risk
  // can pass on risk id to that action create functionality
  // add snackbar once fully working, focus first on functionality
  axiosInstance.post('create-risks/', riskForm.value)
    .then((response) => {
      // emit id of newly created risk to parent component
      newlyCreatedRiskID.value = response.data.id;
      showForm.value = false;
      emit('riskAdded', props.paragraphID);
    })
    .catch((error) => {
      //handle error
      devLog(error);
    })
    .then(() => {
      //always executed
    });
};

function putRisk() {
  axiosInstance.put('risks/' + riskForm.value.id + '/', riskForm.value)
    .then((response) => {
      // emit id of newly updated risk to parent component
      newlyCreatedRiskID.value = riskForm.value.id;
      showForm.value = false;
      emit('riskEdited', props.paragraphID);
    })
    .catch((error) => {
      //handle error
      devLog(error);
    })
    .then(() => {
      //always executed
    });
};

</script>

<style scoped>
:deep(.p-button).accept-button {
  color: white;
}

/* FORM */
.risk-form,
.risk-form-empty,
.risk-form-undefined {
  color: #1A224C;
  padding: 0.5rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: start;
  width: 100%;
}

.form-field {
  margin-top: 0.5rem;
  display: flex;
  width: 100%;
  margin-bottom: 0.5rem;
}

.form-item {
  width: 100%;
}

.form-button {
  margin-top: 1rem;
}

.error {
  color: red;
}

textarea {
  width: 100%;
  padding: 0.75rem;
  outline: none;
  background-color: white;
  border: 1px solid #6D76B4;
  color: var(--main-dark-purple);
  overflow-y: scroll;
}

.buttons-field {
  align-items: flex-end;
  margin-top: 2.5rem;
}

.selected-text {
  font-style: oblique;
  color: var(--main-dark-purple);
}

.text-field {
  color: var(--main-dark-purple);
  font-style: italic;
}
</style>